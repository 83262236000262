import React from 'react';
import './App.css';


function App() {
  return (
    <div className="app-container">
      <nav class="nav-bar">
        <div class="logo">
          <img src="https://styles.redditmedia.com/t5_penom/styles/communityIcon_386whbh0z5041.png" alt="Logo" />
       </div>
        </nav>
    
      
  <div class="card-container">
    <div class="card-group">
      <div class="card">
    <a href="https://www.google.com/" class="hero-image-container">
        <img class="same-size-img" src="https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/image8-2.jpg?width=600&name=image8-2.jpg" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://www.google.com/">Google Chrome</a></h1>
        <p>Google dominates in the US, handling 60% of all search queries — but that still presents a huge opportunity for marketers to reach the other 40%.</p>
        
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://search.brave.com/" class="hero-image-container">
        <img class="same-size-img" src="https://logowik.com/content/uploads/images/brave-browser4011.jpg" alt="" />
      </a>
      <div class="main-content">
        <h1><a href="https://search.brave.com/">Brave</a></h1>
        <p>Brave’s default settings don’t include AI search, but once you toggle on “search with AI,” it delivers AI summaries above traditional search results.</p>
        </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://duckduckgo.com/" class="hero-image-container">
        <img class="same-size-img" src="https://i.pcmag.com/imagery/articles/03dMezAlZfCtWtcQt4PWaG6-1.fit_lim.v1569486053.jpg" alt="" />
      </a>
      <div class="main-content">
        <h1><a href="https://duckduckgo.com/">Duckduckgo</a></h1>
        <p>If you‘re uneasy about targeted ads or don’t want your search data stored, you might want to try DuckDuckGo, which touts itself as "the search engine that doesn’t track you."</p>
        <div class="flex-row">
         
         
            </div>
            </div>
            
          
          
        </div>
        
      </div>
     
    </div>

    <div class="card-container">
    <div class="card-group">
      <div class="card">
    <a href="https://www.baidu.com/" class="hero-image-container">
        <img class="same-size-img" src="https://g.foolcdn.com/image/?url=https://g.foolcdn.com/editorial/images/506739/baidu-logo.png&op=resize&w=700" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://www.baidu.com/">Baidu</a></h1>
        <p>Baidu is China’s largest search engine, capturing 52.15% of China’s search market.</p>
        <div class="flex-row">
         
            </div>
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://www.ask.com/" class="hero-image-container">
        <img class="same-size-img" src="https://logos-world.net/wp-content/uploads/2022/05/Ask.com-Logo-700x394.jpg" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://www.ask.com/">Ask.com</a></h1>
        <p>Ask.com, formerly known as Ask Jeeves, is a search engine that was designed — pre-AI-powered search — to answer questions.</p>
     
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://m.naver.com/" class="hero-image-container">
        <img class="same-size-img" src="https://fontslogo.com/wp-content/uploads/2013/03/naver-Logo-Font.jpg" alt="" />
      </a>
      <div class="main-content">
        <h1><a href="https://m.naver.com/">Naver</a></h1>
        <p>More than a simple search engine, Naver users can access news content, search suggestions, and more.</p>
       
           
            </div>
            
          
          
        </div>
        
      </div>
     
    </div>


    <div class="card-container">
    <div class="card-group">
      <div class="card">
    <a href="https://www.bing.com/" class="hero-image-container">
        <img class="same-size-img" src="https://www.gizmochina.com/wp-content/uploads/2020/10/Microsoft-Bing-Logo-2020-1024x683.jpg" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://www.bing.com/">Microsoft Bing</a></h1>
        <p>Bing’s search pages look similar to Google’s, with the same categories and a similar white background, with blue links and green URLs.</p>
       
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://www.yahoo.com/" class="hero-image-container">
        <img class="same-size-img" src="https://1000logos.net/wp-content/uploads/2017/05/Yahoo-logo-500x333.jpg"  alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://www.yahoo.com/">Yahoo</a></h1>
        <p>Yahoo! is powered by Microsoft Bing, so search results are similar. Its search results page has a minimalist, text-heavy interface</p>
       
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://www.aol.com/" class="hero-image-container">
        <img class="same-size-img" src="https://www.logodesignlove.com/images/evolution/old-aol-logo.jpg" alt="" />
      </a>
      <div class="main-content">
        <h1><a href="https://www.aol.com/">AOL</a></h1>
        <p>AOL’s search results page is very ad-heavy — you’ll have to look below the fold to see your results.</p>
       
            </div>
            
          
          
        </div>
        
      </div>
     
    </div>

    <div class="card-container">
    <div class="card-group">
      <div class="card">
    <a href="https://archive.org/" class="hero-image-container">
        <img class="same-size-img" src="https://logowik.com/content/uploads/images/internet-archive6919.jpg" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://archive.org/">Internet Archive</a></h1>
        <p>Internet Archive is a different kind of search engine. As its name implies, Internet Archive is a free archive of books, movies, software, music, websites, etc.</p>
       
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://yep.com/" class="hero-image-container">
        <img class="same-size-img" src="https://bold-awards.com/wp-content/uploads/elementor/thumbs/Yep.com-Featured-Image-q7aw3zedjnyhwuj3eq4pkakjw0ly2wsm95mdaa3944.jpg" alt=""/>
      </a>
      <div class="main-content">
        <h1><a href="https://yep.com/">Yep</a></h1>
        <p>Yep’s business model . It shares 90% of its ad revenue with content creators in an effort to encourage more people to share their knowledge and high-quality content.</p>
       
            </div>
            
          
          
        </div>
        <div class="card">
    <a href="https://www.ecosia.org/" class="hero-image-container">
        <img class="same-size-img" src="https://logowik.com/content/uploads/images/t_ecosia-search-engine7462.logowik.com.webp" alt="" />
      </a>
      <div class="main-content">
        <h1><a href="https://www.ecosia.org/">Ecosia</a></h1>
        <p>Ecosia is most popular in its home country of Germany, where it has 0.87% of the market. Like Yahoo!, Ecosia runs on Microsoft Bing.</p>
      
            </div>
            
          
          
        </div>
        
      </div>
     
    </div>
    
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">Case studies examine how you achieved success in a particular situation. Case studies often border on the fine line between content marketing and advertising. So include practical takeaways for the audience.</p>
          </div>
</div>
          

          
        
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
         <a href="THINK YOU FOR CLICKING">WEB MASTER</a>
            </p>
          </div>

          
        </div>
      </div>
</footer>

</div>

  


  );
}

export default App;